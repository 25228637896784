<template>
    <div class="empty-page"></div>
</template>

<script>
import axios from '@/service/axios';
import VueCookies from 'vue-cookies';
import router from '@/router';

export default {
  name: 'installablePlayerCallback',
  data () {
    return {
      email: this.$route.query.email,
      aToken: this.$route.query.accessToken,
      rToken: this.$route.query.refreshToken,
      url: this.$route.query.redirectUrl
    };
  },
  mounted () {
    /* console.log("email", this.$route.query.email);
    console.log("accessToken", this.$route.query.accessToken);
    console.log("refreshToken", this.$route.query.refreshToken);
    console.log("redirectUrl", this.$route.query.redirectUrl);
    console.log("spaceId", this.$route.query.spaceId);
    console.log("url", window.location.href); */
    this.setCookie();
    this.login();
  },
  methods: {
    setCookie () {
      // comeFrom값 가져오기
      const comeFrom = VueCookies.get('comeFrom');
      VueCookies.keys().forEach((cookie) => this.$cookies.remove(cookie));
      // comeFrom값 다시 셋팅
      VueCookies.set('comeFrom', comeFrom);
      sessionStorage.removeItem('carCurationSpace');
      this.$store.commit('initData');
      if (this.$route.query.accessToken != null) {
        // 토큰값 있을 경우
        VueCookies.set('aToken', this.$route.query.accessToken, '30d');
        VueCookies.set('rToken', this.$route.query.refreshToken, '30d');
        VueCookies.set('email', this.$route.query.email);

        this.$store.commit('setEmail', this.$route.query.email);
        this.$route.query.spaceId != null ? VueCookies.set('spaceId', this.$route.query.spaceId) : VueCookies.remove('spaceId');
      }
    },
    login () {
      if (this.$route.query.accessToken != null) {
        // 토큰값 있을 경우
        const email = VueCookies.get('email');
        const aToken = VueCookies.get('aToken');
        const headers = { 'X-AUTH-TOKEN': aToken };
        axios
          .post('/api/user/getLoginUser', { email }, { headers })
          .then((res) => {
            if (res.data.resultCd === '0000') {
              const result = res.data.result;
              const userId = result.userId;
              const email = result.email;
              const comeFrom = result.comeFrom;
              const userType = result.userType;
              const signupCouponAt = result.signupCouponAt;
              const division = result.division;
              // 쿠키 셋팅
              VueCookies.set('userId', userId);
              VueCookies.set('email', email);
              VueCookies.set('comeFrom', comeFrom);
              VueCookies.set('userType', userType);
              // store 셋팅
              this.$store.commit('setEmail', email);
              this.$store.commit('setSignupCouponAt', signupCouponAt);
              this.$store.commit('setDivision', division);
              this.$store.commit('setInstallablePlayer', true);
            }
          })
          .then(() => {
            // 큐레이션 설정/결제 페이지로 이동할 경우 로컬스토리지에 spaceId값 저장
            if (this.$route.query.redirectUrl === 'UpdateCuration' || this.$route.query.redirectUrl === 'SpacePayment') localStorage.setItem('spaceId', this.$route.query.spaceId);
            router.push({ name: this.$route.query.redirectUrl });
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        // 토큰값 없을 경우
        router.push({ name: this.$route.query.redirectUrl });
      }
    }
  }
};
</script>
